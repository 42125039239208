.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}

.red {
  color: red;
  font-style: normal;
  font-weight: bold;
}

.green {
  color: green;
  font-style: normal;
  font-weight: bold;
}

.btc {
  color: #FF9900;
  font-style: normal;
  font-weight: bold;
}

.eur {
  color: black;
  font-style: normal;
  font-weight: bold;
}

.addMargin {
  margin-top: 50px !important;
  color: gray;
}

.titleMargin {
  margin-bottom: 25px !important;
}